import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('workflows?page=' + page,  {params});
    }, 
    show(id) {
        return Service().get('workflows/show/'+id);
    },    
    create(params) {
        return Service().post('workflows/create', params);
    },
    update(id, params) {
        return Service().post('workflows/update/'+id, params);
    },
    delete(id) {
        return Service().post('workflows/delete/'+id);
    }
}