import Service from '@/helpers/service'

export default {

    get(params) {
        return Service().get('condition', {params});
    }, 
    show(id) {
        return Service().get('condition/show/'+id);
    },    
    create(params) {
        return Service().post('condition/create', params);
    },
    update(id, params) {
        return Service().post('condition/update/'+id, params);
    },
    delete(id) {
        return Service().post('condition/delete/'+id);
    }
}